/** Auth starts */
export const LOGIN_PATH = "/login";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset-password";
/** Auth ends */

/****** Groups starts *****/
export const PATH_MANAGE_GROUP = "/groups/manage";
export const PATH_CREATE_GROUP = "/groups/create";
/****** Groups ends *****/

/****** News starts *****/
export const PATH_MANAGE_NEWS = "/news/manage";
export const PATH_CREATE_NEWS = "/news/create";
export const PATH_VIEW_NEWS = "/news/view";
/****** News ends *****/

/** User starts */
export const CREATE_USER_PATH = "/employee/create";
export const MANAGE_USER_PATH = "/employee/manage";
export const MY_PROFILE_PATH = "/employee/profile";
export const PASSWORD_PATH = "/employee/password";
/** User ends */

/** Posts starts */
export const PATH_CREATE_POST = "/posts/create";
/****** Posts ends *****/

/** Translations starts */
export const PATH_EDIT_TRANSLATION = "/translation/edit";
export const PATH_MANAGE_TRANSLATION = "/translation/manage";
/** Translations ends */

/** Documents starts */
export const PATH_CREATE_DOCUMENTS = "/documents/create";
export const PATH_MANAGE_DOCUMENTS = "/documents/manage";
/** Documents ends */

/** Alerts starts */
export const PATH_CREATE_ALERTS = "/alerts/create";
export const PATH_MANAGE_ALERTS = "/alerts/manage";
/** Alerts ends */

/** Forms starts */
export const PATH_CREATE_FORMS = "/forms/create";
export const PATH_MANAGE_FORMS = "/forms/manage";
export const PATH_VIEW_FORMS = "/forms/view";
export const PATH_VIEW_FORM_DATA = "/forms/form-data"
/** Forms ends */

export const PATH_CHATS = "/chats";

/**Faq */
export const PATH_MANAGE_FAQ = "/faq-categories";
export const EDIT_FAQ = "/edit-faq/:id";
/**Faq ends */
